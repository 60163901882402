export const appBaseURL = "/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, viewport-fit=cover, user-scalable=yes"},{"name":"version","content":"0.0.1"},{"name":"build-date","content":"prod:2024-11-14-9-11"},{"name":"format-detection","content":"telephone=no"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":""},{"rel":"preload","href":"https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Ubuntu:wght@400;700&display=swap","as":"style"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Staatliches&display=swap"},{"rel":"apple-touch-icon","type":"image/x-icon","href":"/apple-touch-icon.png"},{"rel":"icon","type":"image/png","href":"/android-chrome-192×192.png","sizes":"192x192"}],"style":[],"script":[{"src":"https://www.youtube.com/iframe_api","type":"text/javascript","async":true}],"noscript":[],"htmlAttrs":{"lang":"ja"},"title":"","titleTemplate":""}

export const appPageTransition = false

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false