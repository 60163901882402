<template>
  <div class="error-container">
    <div class="error-content">
      <FontAwesomeIcon
        v-bind:icon="['far', 'frown']"
        class="error-icon"
      />
      <div class="error-title">
        404 not found
      </div>
      <div class="error-subtitle">
        ご指定のページが見つかりません
      </div>
      <div class="error-detail">
        お探しのページは、削除されたか、<br>URLが間違っている可能性があります。
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// ------------------------------------------
// props
// ------------------------------------------
interface ErrorPage {
  error: any,
}
const props = withDefaults(defineProps<ErrorPage>(), {
  error: {
    statusCode: 0,
    statusMessage: '',
    message: '',
    // apiが返すエラー
    data: {
      errorCode: '',
      errorMessage: '',
      errorMessageDetail: '',
    },
  },
})

console.log('error', props.error?.statusCode, props.error?.data)

// ---------------------------------------------------
// data

useHead({
  title: 'エラー',
  meta: [
    { name: 'robots', content: props.error?.statusCode === 404 ? 'noindex' : 'index' },
    { name: 'description', content: '' },
  ],
})

// error.vue からの全てのリンクはclearErrorでリダイレクトしないといけない（仕様バグ回避）
const router = useRouter()
const removeNavigationGuard = router.beforeEach((to) => {
  removeNavigationGuard()
  clearError({ redirect: to.fullPath })
})

onMounted(() => {
  useIndexStore().setIsContentLoaded(true)
})
</script>

<style lang="scss" scoped>
.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-color: #dffbff;
  text-align: center;
}

.error-content {
  padding: 20px;
  text-align: center;
}

.error-icon {
  margin-bottom: 20px;
  color: #889097;
  font-size: 96px;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 115.2px;
}

.error-title {
  margin-bottom: 14px;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 21.6px;
}

.error-subtitle {
  margin-bottom: 28px;
  padding: 0 28px;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 28.8px;
}

.error-detail {
  margin-bottom: 20px;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  line-height: 21px;
}
</style>
