export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      convertYouTubeUrl (url) {
        if (!url) { return url }
        let videoId = null

        if (url.includes('youtube.com/watch')) {
          videoId = url.split('v=')[1]?.split('&')[0]
        } else if (url.includes('youtube.com/embed/')) {
          videoId = url.split('embed/')[1]?.split('?')[0]
        }

        return videoId ? `https://www.youtube.com/embed/${videoId}?enablejsapi=1&modestbranding=1&controls=0&rel=0&disablekb=1&fs=0&playsinline=1` : url
      },
      hexToRgba (hex, opacity) {
        const bigint = parseInt(hex.replace('#', ''), 16)
        const r = (bigint >> 16) & 255
        const g = (bigint >> 8) & 255
        const b = bigint & 255

        return `rgba(${r},${g},${b},${opacity})`
      },
    },
  }
})
